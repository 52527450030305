import React from "react";

import PageHeader from "../../components/Header/pageHeader";
import PageDetalheTitle from "../../components/Header/pageDetalheTitle";
import PageContent from "../../components/Content/";



export default function  Acomodacoes(){
  
      return(
          <>  
            <PageHeader />
            <PageDetalheTitle 
            title="Nossas Acomodações"
            description="Conheça nossas acomodações e escolha a melhor para desfrutar ao máximo sua estada conosco no Amazon Jungle Palace. A torre principal é constituída pelas suítes: Nobre, Master e Imperial Perfeitas para quem gosta de espaço, requinte, luxo e uma dose extra de conforto.
            Já na torre secundária estão localizadas as categorias: superiores, luxo e junior, ideal para quem busca economia sem abrir mão do conforto e comodidade. " />
            <PageContent />




          </>
      );
}