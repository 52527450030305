import { useState } from 'react';
import './style.css';

function Motor(){
    const [fixo, setFixo]               = useState(false);
    const [data_atual, setDataAtual]    = useState('');
    const [data_fim, setDataFim]        = useState('');
    const [adulto, setAdulto]           = useState('');
    const [crianca, setCrianca]         = useState('');
    /*let date = new Date();
    let dia_atual   = date.getDate().toString().padStart(2, "0");
    let mes_atual   = (date.getMonth() + 1).toString().padStart(2, "0");
    let ano_atual   = date.getFullYear();*/
  
    
    const handleDateAtual = (value) => {
        //let data_atual  = `${ano_atual}-${mes_atual}-${dia_atual}`;
        setDataAtual(value);
    }

    const handleDateFim = (value) => {
        setDataFim(value);
    }

    const handleAdulto = (value) => {
        setAdulto(value);
    }

    const handleCrianca = (value) => {
        setCrianca(value);
    }

    const handleSubmit = () => {
       
        //alert(data_atual);
        
        window.open(`https://hbook.hsystem.com.br/Booking?companyId=61ae3ae83c56c5ca354513ee&checkin=${data_atual}&checkout=${data_fim}&adults=${adulto}&children=0&promocode=`);
        //e.preventDefault();

    }

    const handleScrollTop = () =>{
        console.log(window.screen.height);
        if(window.scrollY > 0){
            setFixo(true);
        }else{
            setFixo(false);
        }
        // console.log(window.screenY);
    }
    
    window.addEventListener("scroll", handleScrollTop);
   
    return(
        
        <>
            <div className={fixo == true ? 'wrapper fixo' : 'wrapper'} >
                <div className="container">
                    <div className="form_search">
                       <label>
                            <input type="text" placeholder="00/00/0000" 
                                            value={data_atual}
                                            className="input_text_date"
                                            onChange={(e) => handleDateAtual(e.target.value)} 
                                            required/>   
                            <img src={process.env.PUBLIC_URL+'/assets/img/icon-date.png'} alt="Data Inicial"/>
                        </label> 
                        <label>
                            <input type="text" placeholder="00/00/0000" 
                                            value={data_fim}
                                            onChange={(e) => handleDateFim(e.target.value)}
                                            className="input_text_date"/>   
                            <img src={process.env.PUBLIC_URL+'/assets/img/icon-date.png'} alt="Data Fim"/>
                        </label> 
                        <label>
                            <input type="text" placeholder="2 Adultos" 
                                            value={adulto}
                                            onChange={(e) => handleAdulto(e.target.value)}
                                            className="select_text"/>  
                            <img src={process.env.PUBLIC_URL+'/assets/img/icon-arrow-down.png'} alt="Arrow Down"/> 
                        </label>
                        <label>
                            <input type="text" placeholder="2 Crianças"
                                            value={crianca}
                                            onChange={(e) => handleCrianca(e.target.value)}
                                            className="select_text"/>  
                            <img src={process.env.PUBLIC_URL+'/assets/img/icon-arrow-down.png'} alt="Arrow Down"/> 
                        </label>
                        <label>
                            <button className="btn btn_success"  onClick={handleSubmit}>Simular Reserva</button>
                        </label>

                    </div>
                </div>
            </div>
        </>
    );
}
export default Motor;