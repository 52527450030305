import { Swiper, SwiperSlide } from 'swiper/react';
import PageHeader from "../../components/Header/pageHeader";
import PageTitle from "../../components/Header/pageTitle";
import Section_Midias from "../../components/Section/Midias";
import Footer from "../../components/Rodape";
import Motor from '../../components/Motor';
import { HashLink } from 'react-router-hash-link';
import "./style.css";


export default function Experiencias(){
    return(
    <>
        <PageHeader />
        <PageTitle 
        title="A melhor experiência de selva é no Amazon Jungle Palace"
        description="Conheça a Amazônia de forma autêntica e com o charme e conforto que você merece, está em busca de uma boa dose de aventura e conhecer as incríveis belezas naturais da Amazônia? Temos os melhores e mais completos pacotes de selva. Todos os pacotes incluem:  Traslado fluvial in e out, ponto de embarque em Manaus - Píer particular do hotel; Café da manhã, almoço e jantar e bebidas não alcoólicas. Escolha o seu:" />


        <div className="page-section-content">
            <div className="page-section-container">
       
            </div>

            <Swiper
                loop={true}
                spaceBetween={5}
                slidesPerView={5}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                   
                    <SwiperSlide>
                        <HashLink smooth to="/experiencias#0" className="swiperslider-link">
                            <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/tucano.jpg'} height="250px" /> 
                            TUCANO - 2 DIAS e 1 NOITE
                        </HashLink>
                    </SwiperSlide>
                    <SwiperSlide>
                        <HashLink smooth to="/experiencias#1" className="swiperslider-link">
                            <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/caroco.jpg'} height="250px" /> 
                            CAROÇO 3 DIAS e 2 NOITES
                        </HashLink>
                    </SwiperSlide>
                    <SwiperSlide>
                        <HashLink smooth to="/experiencias#2" className="swiperslider-link">
                            <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/caboclos.jpg'} height="250px" /> 
                            CABOCLOS - 4 DIAS e 3 NOITES
                        </HashLink>
                    </SwiperSlide>
                    <SwiperSlide>
                        <HashLink smooth to="/experiencias#3" className="swiperslider-link">
                            <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/solimoes.jpg'} height="250px" /> 
                            PARICATUBA - 5 DIAS e 4 NOITES
                        </HashLink>
                    </SwiperSlide>

                    <SwiperSlide>
                        <HashLink smooth to="/experiencias#4" className="swiperslider-link">
                            <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/jungle.jpg'} height="250px" /> 
                            JUNGLE PALACE - 6 DIAS e 4 NOITES 
                        </HashLink>
                    </SwiperSlide>
                </Swiper>
            <div className="section-wrapper" id="0">
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-image">
                        <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/tucano.jpg'}/> 
                    </div>
                </div>   
                
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-title">TUCANO - 2 DIAS e 1 NOITE </div>     
                    <div className="section-wrapper-item-description">
                        <p>Ideal para você que deseja conhecer a Amazônia, porém tem pouco tempo!<br /><br /> </p>
                        
                        
                        <ul className="section-wrapper-list">   
                            <span className="bold">Passeios inclusos: </span>  
                            <li>Canoagem pelo lago Salvador;</li>
                            <li>Contemplação do pôr do sol;</li>
                            <li>Trilha interpretativa em floresta de terra firme;</li>
                            <li>Pescaria recreativa de piranhas e outros peixes, além de atividades recreativas.</li>
                        </ul>
                    
                    </div>
                    
                </div>   
                
            </div> 


            <div className="section-wrapper" id="1">
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-title">CAROÇO  3 DIAS e 2 NOITES</div>     
                    <div className="section-wrapper-item-description">
                        <p>Perfeito para você que não abre mão de uma dose extra de anetura na Amazônia.</p>
                    
                        <ul className="section-wrapper-list">   
                            <span className="bold">Passeios inclusos: </span>  
                            <li>Trilha interpretativa em floresta de terra firme;</li>
                            <li>Pescaria recreativa de piranhas e outros peixes; Focagem noturna;</li>
                            <li>Caminhada Noturna na floresta, Arco e flecha e zarabatana;</li>
                            <li>Encontro das águas e lago Janauari;</li>
                            <li>Além de atividades recreativas;</li>
                        </ul>                   
                    
                    </div>
                    
                </div>   

                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-image">
                        <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/caroco.jpg'}/> 
                    </div>
                </div>   
                

                
            </div> 

            <div className="section-wrapper" id="2">
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-image">
                        <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/caboclos.jpg'}/> 
                    </div>
                </div>   
                
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-title">CABOCLOS - 4 DIAS e 3 NOITES</div>     
                    <div className="section-wrapper-item-description">
                        <p>Feito para você que deseja conhecer o melhor da Amazônia.</p>


                        <ul className="section-wrapper-list">   
                            <span className="bold">Passeios inclusos: </span>  
                            <li>Trilha interpretativa em floresta de terra firme;</li>
                            <li>Pescaria recreativa de piranhas e outros peixes; Focagem noturna;</li>
                            <li>Caminhada Noturna na floresta, Arco e flecha e zarabatana;</li>
                            <li>Encontro das águas e lago Janauari;</li>
                            <li>Visita a comunidade do Guedes e a casa de farinha seus costumes;</li>
                            <li>Interação com boto cor de rosa e visita ao sítio bom futuro; Além de atividades recreativas.</li>
                        </ul>                   
                    
                    
                    </div>
                    
                </div>   
                
            </div> 


            <div className="section-wrapper" id="3">
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-title">PARICATUBA - 5 DIAS e 4 NOITES</div>     
                    <div className="section-wrapper-item-description">
                        <p>Feito para você que deseja conhecer o melhor da Amazônia.</p>

                        <ul className="section-wrapper-list">   
                            <span className="bold">Passeios inclusos: </span>  
                            <li>Trilha interpretativa em floresta de terra firme;</li>
                            <li>Pescaria recreativa de piranhas e outros peixes; Focagem noturna;</li>
                            <li>Caminhada Noturna na floresta, Arco e flecha e zarabatana;</li>
                            <li>Encontro das águas e lago Janauari;</li>
                            <li>Visita a comunidade do Guedes e a casa de farinha seus costumes;</li>
                            <li>Interação com boto cor de rosa e visita ao sítio bom futuro;</li>
                            <li>Visita a vila de Paricatuba; Além de atividades recreativas.</li>
                        </ul>   


                    </div>
                    
                </div>   

                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-image">
                        <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/solimoes.jpg'}/> 
                    </div>
                </div>   
                
            </div> 


            <div className="section-wrapper" id="4">
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-image">
                        <img src={process.env.PUBLIC_URL+'/assets/img/experiencia/jungle.jpg'}/> 
                    </div>
                </div>   
                
                <div className="section-wrapper-item">
                    <div className="section-wrapper-item-title">JUNGLE PALACE - 6 DIAS e 4 NOITES </div>     
                    <div className="section-wrapper-item-description">
                        <p>Feito para você que deseja conhecer o melhor da Amazônia.</p>


                        <ul className="section-wrapper-list">   
                            <span className="bold">Passeios inclusos: </span>  
                            <li>Trilha interpretativa em floresta de terra firme;</li>
                            <li>Pescaria recreativa de piranhas e outros peixes; Focagem noturna;</li>
                            <li>Caminhada Noturna na floresta, Arco e flecha e zarabatana;</li>
                            <li>Encontro das águas e lago Janauari;</li>
                            <li>Visita a comunidade do Guedes e a casa de farinha seus costumes;</li>
                            <li>Visita a vila de Paricatuba; </li>
                            <li>Interação com boto cor de rosa e visita ao sítio bom futuro; Além de atividades recreativas.</li>
                        </ul>                   
                    
                    
                    </div>
                    
                </div>   
                
            </div>



        </div>

        <Section_Midias />
        <Footer />
        <Motor />

    </>
    );
}