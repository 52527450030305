import './App.css';
import Routes from './routes';
//import Home from './pages/Home';
//import logo from './logo.svg';


function App() {
  return (
      <Routes />  
  );
}

export default App;
